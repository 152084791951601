<template>
  <el-dialog :visible.sync='dialogVisible' v-bind='$attrs' v-on='$listeners'
             :title='title' width='1000px' append-to-body>

    <div style='width: 800px;margin: 10px auto;border: #f2f2f2 1px dashed;padding: 10px;border-radius: 4px'>
      <div class='flex-sb'>
        <el-link @click='getInfo' style='cursor: pointer;font-size: 16px;'>{{ searchCondition.year
          }}年第{{ searchCondition.quarter
          }}季度《{{ searchCondition.dept_name }}》
        </el-link>
        <el-button size='mini' type='text' @click='showMoreEvent'
                   :icon="!showMore?'el-icon-caret-bottom':'el-icon-caret-top'">更多
        </el-button>
      </div>
      <div>
        <div style='display: flex;flex-direction: row;align-items: center;gap: 5px;margin-bottom: 8px'>
          <artist-search @handleSelect='handleSelectArtist'></artist-search>
          <el-button type='primary' @click='handleAdd' :disabled='loadingAdd' :loading='loadingAdd'>添加</el-button>
          <el-tooltip class='item' effect='light' content='可后添加或部门总监列表批量追加' placement='right'>
            <span class='el-icon-question'></span>
          </el-tooltip>
        </div>
        <el-table :data='kol_range' stripe highlight-current-row>
          <el-table-column label='序号' type='index' width='50'></el-table-column>
          <el-table-column label='红人昵称' min-width='240px' show-overflow-tooltip>
            <template slot-scope='{row}'>
              <span :title='`${row.artist_id}`'>{{ row.nickname || '' }}</span>
              <span style='font-weight: lighter'> ( {{ row.group_name || '' }})</span>
              <span style='float: right'>  【{{ row.creator_name || '' }} 】创建于  {{ row.created_at || '' }}</span>
            </template>
          </el-table-column>
          <el-table-column width='80px'>
            <template slot-scope='{row}'>
              <el-link type='danger' icon='el-icon-close' @click='handleRemoveKol(row)'></el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if='searchCondition.id' class='flex-sb' style='margin: 10px;'>

        <div>
          <span style='font-size: 1.1em'>总监：{{ currentEditRow.manager_name || '' }}</span>
          <el-link type='primary' icon='el-icon-edit-outline'
                   @click='handleEditManager(currentEditRow)'></el-link>
        </div>

        <el-button type='primary' icon='el-icon-refresh' :loading='loadingInit' :disabled='loadingInit'
                   @click='handleInit'>初始化绩效目标
        </el-button>
      </div>
    </div>

    <div v-if='showMore'>
      <div style='width: 100%;display: flex;flex-direction: row;gap: 10px'>
        <div style='width: 260px;border-radius: 4px;border: #f2f2f2 1px dashed;padding: 5px'>
          <div style='display: flex;flex-direction: row;gap: 5px;padding:5px;margin: auto'>
            <span style='font-weight: bold'>签约状态：</span>
            <el-checkbox-group v-model='checkList' @change='getKolList'>
              <el-checkbox label='Y'>在约</el-checkbox>
              <el-checkbox label='N'>解约</el-checkbox>
            </el-checkbox-group>
          </div>
          <el-table :data='deptList' max-height='300px' stripe highlight-current-row
                    @selection-change='handleSelectionChange'>
            <el-table-column label='序号' type='index' width='50'></el-table-column>
            <el-table-column type='selection' width='55'></el-table-column>
            <el-table-column label='部门' min-width='100px'>
              <template slot-scope='{row}'>
                <span>{{ row.dept_name || '' }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style='flex: auto'>
          <div style='display: flex;flex-direction: row;align-items: center;gap: 5px;margin-bottom: 8px'>
            <artist-search @handleSelect='handleSelectArtist'></artist-search>
            <el-button icon='el-icon-plus' type='text' @click='appendToKolRange'>添加红人</el-button>
            <el-tooltip class='item' effect='light' content='可后添加或部门总监列表批量追加' placement='right'>
              <span class='el-icon-question'></span>
            </el-tooltip>
          </div>

          <el-table :data='kolList' max-height='300px' highlight-current-row stripe
                    @selection-change='handleKolChange'>
            <el-table-column label='序号' type='index' width='50'></el-table-column>
            <el-table-column
              type='selection'
              width='55'>
            </el-table-column>
            <el-table-column label='红人昵称' min-width='300px'>
              <template slot-scope='{row}'>
                <div :class='[row.sign_status==="Y"?"sign-status":"unsign-status"]'>
                  <span>{{ row.nickname || '' }}</span>
                  <span title='签约时间' class='sign'>（{{ row.group_name || ''
                    }} 签约于{{ $utils.parseTime(row.sign_start, '{y}年{m}月{d}日') }}
                {{ row.sign_status ? (row.sign_status === 'Y' ? '目前：在约' : '目前：解约') : '' }}）
              </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width='100' label='操作项'>
              <template slot-scope='{row}'>
                <el-button title='移除该红人' type='text' icon='el-icon-close' @click='handleRemoveRow(row)'></el-button>
              </template>
            </el-table-column>
          </el-table>

          <div style='text-align: center;padding: 10px;'>
            <el-button round type='primary' size='medium' @click='handleCommit' :disabled='selectionKol.length===0'>
              确认追加({{ selectionKol.length }})
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!--    修改总监-->
    <SelectManager ref='refSelectManager' @handleSelect='handleSelectManager'></SelectManager>
  </el-dialog>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import SelectManager from '@/pages/pa/components/SelectManager'

export default {
  name: 'DeptKolSelect',
  components: { SelectManager, ArtistSearch },
  data() {
    return {
      dialogVisible: false,
      loadingStatus: false,
      loadingAdd: false,
      showMore: false,
      loadingInit: false,
      title: '部门红人',
      searchCondition: {},
      currentEditRow: {},
      deptList: [],
      dataList: [],
      pageData: { page_size: 100, current_page: 1 },
      total: 0,
      selection: [],
      kolList: [],
      selectionKol: [],
      selectedKol: [],
      checkList: ['Y'],
      kol_range: [],
      currentKol: {}
    }
  },
  methods: {
    show(row) {
      this.dialogVisible = true
      this.showMore = false
      this.searchCondition = row
      this.kol_range = []
      this.kolList = []
      this.checkList = ['Y']
      this.selectedKol = []
      // this.kol_range = this.searchCondition.kol_range || []
      this.getInfo()

    },
    async getNextDeptList() {
      if (this.searchCondition.dept_id) {
        let { list } = await this.$api.getNextDeptList(this.searchCondition.dept_id)
        this.deptList = list || []
      }

    },
    showMoreEvent() {
      this.showMore = !this.showMore
      if (this.showMore)
        this.getNextDeptList()
    },
    async handleSave(formData) {

      // if (!this.searchCondition.manager_id) {
      //   this.$notify.warning('总监必填')
      //   return
      // }
      if (!this.searchCondition.dept_id) {
        this.$notify.warning('部门必选')
        return
      }
      if (!this.searchCondition.quarter) {
        this.$notify.warning('季度必选')
        return
      }
      if (!this.searchCondition.year) {
        this.$notify.warning('年份必选')
        return
      }
      if (!formData.nickname) {
        this.$notify.warning('红人必选')
        return
      }
      if (!formData.id) {
        this.$notify.warning('红人ID必传')
        return
      }
      // 考核设置ID
      let data = {
        // id: this.searchCondition.id,
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        dept_id: this.searchCondition.dept_id,
        artist_id: formData.id,
        nickname: formData.nickname,
        group_id: formData.group_id,
        group_name: formData.group_name,
        sign_status: formData.sign_status,
        sign_start: formData.sign_start
      }
      await this.$api.appendPaChiefSettingKol(data)

      this.selectedKol.push(formData)
    },
    async handleSaveSelection(selectionKol) {
      //批量添加
      if (selectionKol.length < 100) {
        let multiple = selectionKol.map(formData => {
          return {
            // id: this.searchCondition.id,
            year: this.searchCondition.year,
            quarter: this.searchCondition.quarter,
            dept_id: this.searchCondition.dept_id,
            artist_id: formData.id,
            nickname: formData.nickname,
            group_id: formData.group_id,
            group_name: formData.group_name,
            sign_status: formData.sign_status,
            sign_start: formData.sign_start
          }
        })
        await this.$api.appendPaChiefSettingKol({ multiple: multiple, searchCondition: this.searchCondition })

      } else {

        //追个添加
        this.selectedKol = []
        const promises = selectionKol.map(item => {
          return this.handleSave(item)
        })
        await Promise.all(promises)
        this.$notify.success('已追加完毕')
      }

      this.$emit('saved')
      this.dialogVisible = false


    },
    handleSelectionChange(val) {
      this.selection = val
      this.getKolList()
    },
    async getKolList() {
      if (this.selection && this.selection.length === 0) {
        return
      }
      this.kolList = []
      let deptIds = this.selection.map((item) => {
        return item.dept_id
      })
      if (deptIds.length > 0) {

        let { list } = await this.$api.getPaDeptKolList({ dept_ids: deptIds, sign_status: this.checkList })
        this.kolList = list || []
      }

    },
    handleKolChange(val) {
      this.selectionKol = val
    },
    handleCommit() {
      if (this.selectionKol.length === 0) {
        this.$notify.warning('请先勾选考核红人')
        return
      }
      let msg = `确认将选中的【${this.selectionKol.length}】个红人添加为《${this.searchCondition.dept_name}》考核红人`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.handleSaveSelection(this.selectionKol)
      }).catch(() => {
        this.$notify.info('取消')
      })
    },
    handleRemoveKol(row) {
      if (!row.artist_id) {
        this.$message.warning('红人ID为空')
        return
      }
      this.$confirm(`确认删除考核红人【${row.nickname}】及已录入的考核目标`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        let posIndex = this.kol_range.indexOf(row)
        this.kol_range.splice(posIndex, 1)
        await this.$api.deletePaChiefSettingKol({ id: this.searchCondition.id, artist_id: row.artist_id })
        // 删除之后刷新数据
        this.$emit('saved')

      }).catch(() => {
        this.$notify.info('取消操作')
      })

    },
    handleRemoveRow(row) {
      let index = this.kolList.indexOf(row)
      this.kolList.splice(index, 1)
    },
    handleSelectArtist(obj) {
      if (obj && obj.id)
        this.currentKol = {
          id: obj.id,
          nickname: obj.nickname,
          group_id: obj.group_id,
          group_name: obj.group_name,
          sign_start: obj.sign_start,
          sign_status: obj.sign_status
        }
    },
    appendToKolRange() {
      if (this.currentKol.id) {
        let obj = { ...this.currentKol }
        if (this.kolList.findIndex(value => value.id === obj.id) === -1) {
          this.kolList.push(obj)
        }
      }
    },
    async handleAdd() {
      this.loadingAdd = true
      setTimeout(() => {
        this.loadingAdd = false
      }, 2000)
      await this.handleSave(this.currentKol)
      this.loadingAdd = false
      await this.getInfo()
      this.currentKol = {}
    },
    async getInfo() {
      let { info } = await this.$api.getPaDeptSetting(this.searchCondition)
      this.kol_range = info.kol_range || []
      this.searchCondition.id = info.id
      this.currentEditRow = info || {}
    },
    handleInit() {
      this.handleInitItem(this.searchCondition)
    },
    async handleInitItem(row) {
      this.loadingInit = true
      setTimeout(() => {
        this.loadingInit = false
      }, 2000)
      let { err, success, tag_total, exist_total } = await this.$api.initItemPaChiefRevenue(row.id)
      if (err) {
        if (err.length > 0) {
          let errMsgArr = err.map(item => {
            return item.err_msg
          })
          this.$message.warning(errMsgArr.join('、'))
        }
        this.$notify.success(`本次初始化${success}条，考核项${tag_total}条，已初始化${exist_total}条`)

      } else {
        this.$notify.success('初始化完毕')
      }

    },
    handleEditManager(row) {
      this.currentEditRow = row
      this.$notify.success('修改总监')
      this.$refs['refSelectManager'].show()
    },
    async handleSelectManager(val) {
      if (val && val.user_id) {
        // let posIndex = this.dataList.indexOf(this.currentEditRow)
        // this.dataList[posIndex]['manager_id'] = val.user_id
        // this.dataList[posIndex]['manager_name'] = val.nickname
        await this.$api.editPaDeptSettingManager({
          id: this.currentEditRow.id,
          manager_id: val.user_id,
          manager_name: val.nickname
        })
        await this.getInfo()
      }

    }
  }
}
</script>

<style scoped>
.sign-status {
  border: var(--success) 1px dashed;
  border-radius: 10px;
  padding: 2px 5px;
}

.unsign-status {
  border: var(--danger) 1px dashed;
  border-radius: 8px;
  padding: 2px 5px;
}

.title-kol {
  border-radius: 4px;
  border: var(--primary) 1px solid;
  padding: 8px 20px;
  text-align: center;
  font-weight: bold;
  /*background-color: var(--primary);*/
  color: var(--primary);
  /*box-shadow: 2px 2px 2px rgba(255, 49, 118, 0.1) inset,  -1px -1px 2px white inset;*/
}
</style>
